import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
    infoButton: {
        
        width: '350px   ',
        height: '130px',
        '&:hover': {
            backgroundColor: '#A8B1F0', // color on hover
        },
        borderRadius: '15px',
    },
    payButton: {
        marginTop: '4%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    }
}));