import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    toolbar: theme.mixins.toolbar,
    title: {
        marginTop: '5%',
        marginBottom: '5%'
    },
    emptyButton: {
        minWidth: '150px',
        [theme.breakpoints.down('xs')]: {
            marginBottom: '5px',
        },
        [theme.breakpoints.up('xs')]: {
            marginRight: '20px',
        },
    },
    checkoutButton: {
        minWidth: '150px',
    },
    link: {
        textDecoration: 'none',
    },
    cardDetails: {
        display: 'flex',
        marginTop: '10%',
        marginBottom: '2%',
        width: '100%',
        justifyContent: 'space-between',
    },
    centerText: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
    ifEmpty: {
        display: 'flex',
        justifyContent: 'center',
    },
    centerInfos: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '4%',
        borderRadius: '10px',
    },
    infos: {
        width: '25vh',
        padding: '10px',
        borderRadius: '10px',
    },
    infoButton: {
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
}));