import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
    root: {
        maxWidth: '100%',
    },
    media: {
        height: 0,
        padding: '50%',
    },
    cardContent: {
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center'
    },
    cardActions: {
        justifyContent: 'center',
        marginBottom: '5%',
    },    
    buttons: {
        display: 'flex',
        alignItems: 'center',
    },
    customButton: {
        backgroundColor: '#DCDCDC', // replace '#yourColor' with your desired color
        '&:hover': {
          backgroundColor: '#C0C0C0', // replace '#yourHoverColor' with your desired hover color
        },
      },
    item: {
        justifyContent: 'center',
    }
}));