import React from 'react'
import { Container, Typography, Button, Grid } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import { Link } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import useStyles from "./styles";
import CartItem from './CartItem/CartItem';
import Footer from "../Gallery/Footer";

const Cart = ({ loading, size, cart, subTotal, onUpdateCartQty, onRemoveFromCart, onEmptyCart }) => {
    const classes = useStyles();

    const handleEmptyCart = () => onEmptyCart();

    const renderEmptyCart = () => (
        <div className={classes.ifEmpty}>
        <Typography variant="subtitle1">Votre panier est vide pour le moment, vous pouvez le remplir en vous rendant sur 
            <Link className={classes.link} to="/oeuvres"> la page des oeuvres.</Link>
        </Typography>
            <div style={{marginBottom: '100%'}}/>
        </div>
    );

    if (!cart) {
        // Afficher un écran de chargement
        return (
            <Box sx={{ display: 'flex' }}>
                <CircularProgress />
            </Box>
        );
    }

    const renderCart = () => (
        <>
            <Tooltip title={
                <Typography className={classes.infos} variant="body2" gutterBottom>
                    - En France (métropole) <br />
                    &emsp;1. Huile sur toile : 200€ <br />
                    &emsp;2. Aquarelle : 25€ <br /><br />
                    - En Europe: <br /> 
                    &emsp;1. Huile sur toile : 250€ <br />
                    &emsp;2. Aquarelle : 50€ <br /> <br />
                    - En Amerique du Nord : <br /> 
                    &emsp;1. Huile sur toile : 350€ <br />
                    &emsp;2. Aquarelle : 80€ <br />
                </Typography>
                }>
                <IconButton color="primary" className={classes.infoButton}>
                    <InfoIcon />
                    <Typography variant="h6">&nbsp;Tarifs de livraison</Typography>
                </IconButton>
            </Tooltip>
            <Grid container justifyContent="center" spacing={3}>
                {cart.map((lineItem) => (
                    <Grid item xs={12} sm={4} key={lineItem.id}>
                        <CartItem loading={loading} item={lineItem} onUpdateCartQty={onUpdateCartQty} onRemoveFromCart={onRemoveFromCart} />
                    </Grid>
                ))}
            </Grid>
            
            <div className={classes.cardDetails}>
                
                <Typography variant="h4">Sous-total (hors livraison): {subTotal}€</Typography>
                <div>
                    <Button className={classes.checkoutButton} component={Link} to="/paiement" size="large" type="button" variant="contained" color="primary">Commander</Button>
                </div>
            </div>
            
        </>
    );

    return (
        <div>
            <Container>
                <div className={classes.toolbar} />
                <div className={classes.centerText}>
                    <Typography className={classes.title} style={{fontFamily: "Big Shoulders Display"}} variant="h3" gutterBottom>Votre panier</Typography>
                </div>
                
                {cart.length > 0 ? renderCart() : renderEmptyCart()}
            </Container>
            <Footer
                role="contentinfo"
                bkgColor={"#151515"}
                color={"white"}
                hoverColor={"#7DF9FF"}
                size={size}
                style={{ position: "fixed", bottom: 0, width: "100%" }}
            />
        </div>
    );
};


export default Cart
