import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
    container: {
        marginTop: '5%',
        maxWidth: '800px',
        margin: '0 auto',
        padding: '20px',
        fontFamily: 'Arial, sans-serif',
        textAlign: 'center',
      },
      title: {
        fontSize: '40px',
        fontWeight: 'bold',
        marginBottom: '5%',
        textDecoration: 'underline',
      },
      section: {
        marginBottom: '40px',
      },
      sectionTitle: {
        fontSize: '28px',
        fontWeight: 'bold',
        marginBottom: '10px',
      },
}));