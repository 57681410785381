import React from 'react'

import useStyles from './styles'

import Footer from '../Gallery/Footer';

const Policy = ({size}) => {
    const classes = useStyles();

    return (
        <div>
        <div className={classes.container}>
          <h1 className={classes.title}>Conditions Générales de Vente</h1>
          <div className={classes.section}>
            <h2 className={classes.sectionTitle}>Les oeuvres</h2>
            <p>
                Toutes les pièces proposées sont des créations uniques réalisées par un artiste contemporain en vie. 
                Cette condition confère aux entreprises le droit à une déduction fiscale. 
                L'acquisition d'une œuvre d'art n'octroie aucun droit de représentation, 
                de diffusion ou de reproduction à l'acheteur. Seul l'artiste conserve ses droits sur sa création. 
                Toute utilisation visuelle des œuvres nécessitera une demande d'autorisation adressée à l'artiste, 
                qui agira en tant qu'intermédiaire dans ce processus.
            </p>
          </div>
          <div className={classes.section}>
            <h2 className={classes.sectionTitle}>Passer une commande</h2>
            <p>
                Pour commander une œuvre, veuillez l'ajouter à votre panier en utilisant les boutons en 
                forme de caddie situés en dessous de chaque œuvre ou sur les pages dédiées. 
                Ensuite, sur la barre de menu en haut à droite de notre site, rendez-vous dans la rubrique "Panier". 
                Vous aurez la possibilité de supprimer une ou plusieurs œuvres si nécessaire.
            </p>
            <p>
                Pour finaliser votre achat, cliquez sur "Livraison". Cela vous dirigera vers une page où vous 
                pourrez estimer les frais de livraison de votre commande. Veuillez saisir vos coordonnées et 
                accepter les conditions générales de vente. Le règlement de votre commande s'effectue par le 
                moyen de paiement mis à votre disposition.
            </p>
          </div>
          <div className={classes.section}>
           <h2 className={classes.sectionTitle}>Moyen de paiment</h2>
           <p>
                Le règlement des achats doit s'effectuer exclusivement en Euros.
            </p>
            <p>
                Pour le paiement par carte bancaire, le débit de votre carte sera effectué dès réception de cette dernière.
            </p>
            <p>
                En ce qui concerne les paiements par chèque, nous n'acceptons que les chèques émis par des banques domiciliées en France métropolitaine.
            </p>
            <p>
                Pour les paiements par virement bancaire, veuillez télécharger notre RIB/IBAN pour effectuer le virement.
            </p>
         </div>
         <div className={classes.section}>
           <h2 className={classes.sectionTitle}>Sécurité des informations bancaires</h2>
           <p>
                Toutes les transactions financières sont traitées par le service sécurisé de Stripe. 
                Vos opérations sont sécurisées, car la page de saisie de vos informations bancaires est cryptée. 
                Cette méthode garantit que vos coordonnées bancaires ne peuvent pas être interceptées. 
                Aucune information sensible, telle que le numéro de carte, la date d'expiration, et le 
                cryptogramme, n'est transmise au vendeur. Vous pouvez ainsi effectuer vos transactions en toute confiance.
            </p>
         </div>
         <div className={classes.section}>
           <h2 className={classes.sectionTitle}>Expédition et Manutention</h2>
            <p>
                1 - La livraison de votre commande sera réalisée à l'adresse que vous avez spécifiée, 
                dans un délai minimum de 20 jours ouvrables. Les frais de livraison seront détaillés à la 
                fin du processus d'achat et seront calculés en fonction du pays de destination. 
                Pour les œuvres de grande taille, le coût du transport peut varier selon l'adresse. 
                N'hésitez pas à nous contacter pour obtenir une confirmation.
            </p>
            <p>
                2 - Les frais de livraison incluent l'emballage soigné et une assurance couvrant la valeur des biens.
                Il est de votre responsabilité de vérifier l'état des colis dès leur réception. 
                Si nécessaire, veuillez indiquer sur le récépissé la mention "sous réserve" afin de 
                bénéficier des garanties contractuelles en cas de détérioration des œuvres.
                Pour vous tenir informé de l'avancement de votre commande, vous recevrez des 
                notifications par email concernant la planification des livraisons. Nous restons à 
                votre disposition pour toute question supplémentaire.
            </p>
         </div>
         <div className={classes.section}>
           <h2 className={classes.sectionTitle}>Commande en attente</h2>
            <p>
                1 - Un courrier de confirmation vous sera envoyé pour officialiser l'acceptation de votre commande. 
                Nous attirons votre attention sur le caractère non contractuel des couleurs des photos reproduites sur internet, 
                car une image sur écran peut différer de manière significative d'une œuvre originale sur toile ou sur papier. 
                Pour les lithographies originales, les commandes se font exclusivement "à la feuille" et ne comprennent pas l'encadrement.
            </p>
            <p>
                2 - En validant votre commande, vous déclarez avoir pris connaissance et accepté les conditions générales de vente.
                Concernant les frais de taxes, pour les livraisons en dehors de la France métropolitaine et de l'Union européenne, 
                vous êtes responsable du règlement de toutes les taxes éventuellement exigibles. Nous vous recommandons de vous 
                renseigner directement auprès des autorités compétentes de votre pays pour obtenir les informations nécessaires. 
                Nous restons à votre disposition pour toute clarification supplémentaire.
            </p>
        </div>
        <div className={classes.section}>
           <h2 className={classes.sectionTitle}>Politique de retour</h2>
            <p>
                Conformément à l'article L121-20 du code de la consommation, 
                vous bénéficiez d'un délai de rétractation de 15 jours. 
                Durant cette période, vous avez la possibilité de nous retourner les œuvres 
                (les frais d'expédition étant à votre charge). Le remboursement sera effectué dans 
                un délai de 15 jours après réception des œuvres retournées.
            </p>
            <p>
                Veuillez noter que nous déclinons toute responsabilité en cas de retard ou d'inexécution de la 
                commande résultant d'un cas de force majeure. Nous restons à votre disposition pour toute question 
                supplémentaire et vous remercions de votre compréhension.
            </p>
        </div>
        <div className={classes.section}>
           <h2 className={classes.sectionTitle}>Confidentialité</h2>
            <p>
                Lors de la passation de commande, vous serez amené à nous fournir certaines informations personnelles. 
                Ces données feront l'objet de traitements informatiques effectués par nos soins et nos prestataires de 
                services dans le but de traiter efficacement votre commande. En confirmant votre commande, 
                vous nous accordez l'autorisation de partager ces informations uniquement dans le dessein du traitement de vos commandes.
            </p>
            <p>
                Conformément à la loi Informatique et Libertés, vous avez le droit de demander la modification de ces 
                informations ou de requérir leur suppression de notre base de données. Pour exercer ces droits, 
                veuillez nous contacter par e-mail. Nous restons à votre disposition pour toute question ou préoccupation.
            </p>
        </div>
        <div className={classes.section}>
           <h2 className={classes.sectionTitle}>Garantie et responsabilité</h2>
            <p>
                Ce contrat est soumis à la loi française. Tout litige découlant de ce contrat sera porté devant les tribunaux de Paris.
            </p>
        </div>
    </div>
        <Footer
            role="contentinfo"
            bkgColor={"#151515"}
            color={"white"}
            hoverColor={"#7DF9FF"}
            size={size}
        />
    </div>
    );
}

export default Policy