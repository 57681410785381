import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
    center: {
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        position: 'fixed', 
        top: 0, 
        left: 0, 
        width: '100%', 
        height: '100%',
        transition: 'backgroundColor 1s'
    },
    centerR: {
        display: 'row', 
        backgroundColor: '#2B2A2A',
        justifyContent: 'center', 
        alignItems: 'center', 
        position: 'fixed', 
        top: 0, 
        left: 0, 
        width: '100%', 
        height: '100%',
        transition: 'backgroundColor 1s'
    },
    root: {
        maxWidth: '100%',
        backgroundColor: '#D6D6DC',
    },
    media: {
        height: 0,
        padding: '50%',
    },
    cardActions: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: '5%',
        
    },
    cardContent: {
        display: 'flex',
        textAlign: 'center',
        justifyContent: 'center',
    },
    img: {
        marginTop: '4%',
    },
    pay: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
    },
    modal: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '80%',
        height: '40%',
        backgroundColor: '#808080', // Optional: This will add a semi-transparent background
        marginLeft: '5%',
        borderRadius: '15px',
        padding: '10px',
    },    
    modalR: {
        display: 'flex',
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '40%',
        backgroundColor: 'white', // Optional: This will add a semi-transparent background
        padding: '10px',
        boxShadow: '0px 0px 10px rgba(0,0,0,0.5)', // Ajoute un effet d'ombre
    }, 
    informations : {
        display: 'flex',
        marginTop: '2%',
        alignContent: 'center',
        alignItems: 'center',
    },
    informationsR : {
        display: 'row',
        marginTop: '2%',
        alignContent: 'center',
        alignItems: 'center',
    },
    closeButton: {
        position: 'absolute',
        right: '8px',
        top: '8px',
        color: 'black',
        backgroundColor: '#D3D3D3',
        '&:hover': {
            backgroundColor: '#c0c0c0', // color on hover
        },
    },
    infoButton: {
        justifyContent: 'flex-end',
        '&:hover': {
            backgroundColor: '#D3D3D3', // color on hover
        },
        borderRadius: '15px',
    },
    priceButton: {
        width: '60%',
        height: '50px',
        justifyContent: 'center',
        '&:hover': {
            backgroundColor: 'black', // color on hover
        },
        borderRadius: '10px',
    },
    priceButtonMod: {
        width: '30%',
        height: '50px',
        justifyContent: 'center',
        '&:hover': { backgroundColor: 'black' },
        borderRadius: '10px',
    },
    textInfos: {
        flexGrow: 3,
    },
    textInfosR: {
        display: 'row'
    },
    payButton: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    }
}));