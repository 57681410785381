import { Grid, Typography, CardMedia, Box } from "@mui/material";
import galleryImage1 from "../../assets/triptique.jpg";
import galleryImageTablet1 from "../../assets/triptique.jpg";
import galleryImageMobile1 from "../../assets/triptique.jpg";

import galleryImage2 from "../../assets/Toile.jpg";
import galleryImageTablet2 from "../../assets/Toile.jpg";
import galleryImageMobile2 from "../../assets/Toile.jpg";

import galleryImage3 from "../../assets/tristan.jpg";
import galleryImageTablet3 from "../../assets/tristan.jpg";
import galleryImageMobile3 from "../../assets/tristan.jpg";

const Gallery = ({ size }) => {
  const margins =
    size === "desktop" ? "80%" : size === "tablet" ? "90%" : "95%";

  return (
    <Grid
      container
      rowSpacing={size === "mobile" ? 0 : 3}
      sx={{ width: `${margins}`, margin: "4% auto", marginBottom: "180px" }}
    >
      <Grid
        container
        item
        spacing={3}
        alignItems="center"
        direction={size === "mobile" ? "column-reverse" : "row"}
      >
        <Grid item sm={5}>
          <Box sx={{marginBottom: "32px" }}>
            <Typography variant="h2">L'heure bleue</Typography>
            <Typography variant="p">
            Peintre, aquarelliste et lithographe vivant et travaillant dans l’Hérault. < br/>
            Il a perfectionné son approche artistique dans l’atelier de son père Georges Hosotte à Irancy, ainsi qu’à la fondation Guerlain, et auprès du graveur d’Alechinsky et de Miro.
            </Typography>
          </Box>
        </Grid>

        <Grid item sm={7}>
          <CardMedia
            component="img"
            image={
              size === "desktop"
                ? galleryImage1
                : size === "mobile"
                ? galleryImageTablet1
                : galleryImageMobile1
            }
            alt="gallery image 1"
          />
        </Grid>
      </Grid>

      <Grid columnSpacing={3} container item sm={12}>
        <Grid item sm={7} marginBottom="20px">
          <CardMedia
            component="img"
            image={
              size === "desktop"
                ? galleryImage2
                : size === "mobile"
                ? galleryImageTablet2
                : galleryImageMobile2
            }
            alt="gallery image 2"
          />
        </Grid>

        <Grid
          container
          direction="column"
          justifyContent="space-between"
          item
          rowSpacing={3}
          sm={5}
        >
          <Grid item>
            <CardMedia
              component="img"
              image={
                size === "desktop"
                  ? galleryImage3
                  : size === "tablet"
                  ? galleryImageTablet3
                  : galleryImageMobile3
              }
              alt="gallery image 3"
            />
          </Grid>
          <Grid item>
            <Box
              sx={{
                backgroundColor: "#151515",
                color: "white",
                padding: "55px 47px",
              }}
            >
              <Typography variant="h4">Venez nous <br/>rendre visite</Typography>
              <Typography variant="p" color="white">
              La galerie d'art Saint Guilhem est ouverte du mardi au samedi
              de 10h à 18h au 21 rue Saint Guilhem à Montpellier 34000.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default Gallery;