import React, { useState } from 'react';
import { Card, CardMedia, CardContent, CardActions, Typography, IconButton, Modal, Button } from '@material-ui/core';
import { AddShoppingCart, Close, Search } from '@material-ui/icons';
import { LoadingButton } from '@mui/lab'
import Fade from '@mui/material/Fade';

import useStyles from './styles';

const Product = ({ loading, cart, size, product, onAddToCart }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleAddToCart = () => onAddToCart(product.id, 1);

    let imgSize = size === 'desktop' ? '80%' : size === 'tablet' ? '100%' : '100%'; 

    const body = (
        <div className={classes.center}>
            <div className={classes.modal}>
                <div className={classes.informations}>
                    <div className={classes.textInfos}>
                        <Button className={classes.closeButton} onClick={handleClose}>
                            <Close />
                        </Button>
                        <Typography gutterBottom variant="h4" component="h2">
                            {product.name}
                        </Typography>
                        <Typography gutterBottom variant="h5" component="h2">
                            {product.price.raw}€
                        </Typography>
                        <Typography dangerouslySetInnerHTML={{ __html: product.description }} variant="body1"/>
                    </div>
                        <LoadingButton
                            variant="contained" 
                            style={{backgroundColor: '#D6D6DC'}} 
                            loading={loading[product.id]} 
                            label="Ajouter au panier" 
                            className={classes.priceButtonMod} 
                            onClick={handleAddToCart}
                            endIcon={<AddShoppingCart style={{color: 'black'}}/>}
                            loadingPosition="end"
                            sx={{color: 'black', fontSize: '17px', textTransform: 'none'}}
                            >
                            {size === 'desktop' && "Ajouter au panier "}
                        </LoadingButton>
                </div>
            </div>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <img  style={{width: imgSize, height: imgSize}} src={product.image.url} alt={product.name} />
                </div>
        </div>
    );

    const bodyResp = (
        <div className={classes.centerR}>
            <div className={classes.modalR}>
                <div className={classes.informationsR}>
                    <div className={classes.textInfosR}>
                        <Button 
                            className={classes.closeButtonR} 
                            onClick={handleClose}
                            style={{backgroundColor: '#848884', marginBottom: '5%'}} 
                        >
                            <Close />
                        </Button>
                        <Typography gutterBottom variant="h4" component="h2">
                            {product.name}
                        </Typography>
                        <Typography gutterBottom variant="h5" component="h2">
                            {product.price.raw}€
                        </Typography>
                        <Typography dangerouslySetInnerHTML={{ __html: product.description }} variant="body1"/>
                    </div>
                        <LoadingButton
                            variant="contained" 
                            style={{backgroundColor: '#848884'}} 
                            loading={loading[product.id]} 
                            label="Ajouter au panier" 
                            className={classes.priceButtonModR} 
                            onClick={handleAddToCart}
                            startIcon={<AddShoppingCart style={{color: 'black'}}/>}
                            loadingPosition="center"
                            sx={{color: 'black', fontSize: '17px', textTransform: 'none'}}
                            >
                            Ajouter au panier&nbsp;
                        </LoadingButton>
                </div>
            </div>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '5%'}}>
                    <img  style={{width: imgSize, height: imgSize}} src={product.image.url} alt={product.name} />
                </div>
        </div>
    );

    return (
        <Card className={classes.root}>
            <div style={{ position: 'relative' }}>
                <CardMedia 
                    className={classes.media} 
                    image={product.image.url} 
                    title={product.name} 
                    onClick={handleOpen} 
                    style={{ cursor: 'pointer' }} 
                />
                <IconButton 
                    color="primary" 
                    className={classes.infoButton} 
                    onClick={handleOpen} 
                    style={{ position: 'absolute', top: '10px', right: '10px' }}
                >
                    <Search />
                </IconButton>
            </div>
            <CardContent>
                <div className={classes.cardContent}>
                    <Typography gutterBottom variant="h4" component="h2" onClick={handleOpen} style={{ cursor: 'pointer', fontFamily: "Big Shoulders Display"}}>
                        {product.name}
                    </Typography>
                </div>
                <div className={classes.cardContent}>
                    <Typography gutterBottom variant="h5" component="h2" onClick={handleOpen} style={{ cursor: 'pointer', fontFamily: "Big Shoulders Display" }}>
                        {product.price.raw}€
                    </Typography>
                </div>
                <Typography dangerouslySetInnerHTML={{ __html: product.description }} variant="body2" color="textSecondary" component="p" />
            </CardContent>
            <CardActions disableSpacing className={classes.cardActions}>
            <div className={classes.payButton}>        
                <LoadingButton
                    variant="contained" 
                    style={{backgroundColor: '#c0c0c0'}} 
                    loading={loading[product.id]} 
                    label="Ajouter au panier" 
                    className={classes.priceButton} 
                    onClick={handleAddToCart}
                    endIcon={<AddShoppingCart style={{color: 'black'}}/>}
                    loadingPosition="end"
                    sx={{color: 'black', fontSize: '17px', textTransform: 'none'}}
                    >
                    Ajouter au panier&nbsp;
                </LoadingButton>
            </div>
            </CardActions>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Fade in={open} timeout={{ enter: 400, exit: 400 }}>
                    {size === "desktop" ? body : bodyResp}
                </Fade>
            </Modal>
        </Card>
    );
};

export default Product;
