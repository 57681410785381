import React, { useState, useEffect } from "react";

import { CssBaseline, Slide, Snackbar } from '@material-ui/core';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { Navbar, Products, Cart, Checkout, Home, Mention, Policy } from './components';
import { commerce } from './lib/commerce';

import { ThemeProvider } from "@mui/material/styles";
import { theme, responsiveTheme } from "./theme";

const App = () => {
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [products, setProducts] = useState([]);
    const [cart, setCart] = useState({});
    const [cartArray, setCartArray] = useState([]);
    const [order, setOrder] = useState({});

    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    const [prevScrollPos, setPrevScrollPos] = useState(0);
    const [visible, setVisible] = useState(true);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const [loadingStates, setLoadingStates] = useState({});

    // COMMERCE.JS FUNCTIONS

    const fetchProducts = async () => {
        const { data } = await commerce.products.list();

        setProducts(data);
    };

    const fetchCart = async () => {
        const cartData = await commerce.cart.retrieve();
        setCart(cartData);
        setIsLoading(false); // Indiquer que les données du panier sont prêtes
    };

    const fetchCartArray = async () => {
        const cartData = await commerce.cart.retrieve();
        setCartArray(cartData.line_items);
        setIsLoading(false); // Indiquer que les données du panier sont prêtes
    };

    const handleAddToCart = async (productId, quantity) => {
        setLoadingStates(prevState => ({ ...prevState, [productId]: true }));
        // Check if the product is already in the cart
        const productInCart = cartArray.find(item => item.product_id === productId);
    
        // If the product is not in the cart, add it
        if (!productInCart) {
            const item = await commerce.cart.add(productId, quantity);
            setCartArray(item.line_items);
        } else {
            handleOpenSnackbar("L'oeuvre se trouve déjà dans votre panier.");
        }
        setLoadingStates(prevState => ({ ...prevState, [productId]: false }));
    };

    const handleOpenSnackbar = (message) => {
        setSnackbarMessage(message);
        setOpenSnackbar(true);
        
        // Automatically close the snackbar after 4 seconds
        setTimeout(() => {
          setOpenSnackbar(false);
        }, 4000);
      };
      

    const handleRemoveFromCart = async (lineItemId) => {
        setLoadingStates(prevState => ({ ...prevState, [lineItemId]: true }));
        const response = await commerce.cart.remove(lineItemId);

        setCartArray(response.line_items);
        setLoadingStates(prevState => ({ ...prevState, [lineItemId]: false }));
    };

    const handleUpdateCartQty = async (lineItemId, quantity) => {
        const response = await commerce.cart.update(lineItemId, { quantity });

        setCartArray(response.line_items);
    };


    const handleEmptyCart = async () => {
        const response = await commerce.cart.empty();

        setCartArray(response.line_items);
    };

    const refreshCart = async () => {
        const newCart = await commerce.cart.refresh();

        setCartArray(newCart);
    };

    const handleCaptureCheckout = async (checkoutTokenId, newOrder) => {
        try {
            const incomingOrder = await commerce.checkout.capture(checkoutTokenId, newOrder);

            setOrder(incomingOrder);

            refreshCart();
        } catch (error) {
            console.log(error);
            setErrorMessage(error.data.error.message);
        }
    };

    // HIDE NAVBAR IF SCROLL DOWN

    const handleScroll = () => {
        const currentScrollPos = window.pageYOffset;
        const visible = prevScrollPos > currentScrollPos;
        setPrevScrollPos(currentScrollPos);
        setVisible(visible);
    };

    useEffect(() => {
        
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [prevScrollPos, visible, handleScroll]);


    // FETCH DATA
    useEffect(() => {
        fetchProducts();
        fetchCart();
        fetchCartArray();
    }, []);


    // UPDATE CART
    useEffect(() => {
        setCart((prevCart) => ({
            ...prevCart,
            line_items: cartArray,
        }));
    }, [cartArray]);

    // HOME PAGE

    const assetRoute =
        windowWidth >= 1200 ? "desktop" : windowWidth >= 600 ? "tablet" : "mobile";
    useEffect(() => {
        const handleWindowResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener("resize", handleWindowResize);

        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    }, []);

    const handleDrawerToggle = () => setMobileOpen(!mobileOpen);

    let subtotal;

    if (cartArray.length > 0) {
        subtotal = cartArray.reduce((accumulator, item) => {
            const itemTotal = item.price.raw * item.quantity;
            return accumulator + itemTotal;
        }, 0);
    } else
        subtotal = 0;


    return (
        <Router>
            <div style={{ display: 'row' }}>
                <CssBaseline />
                <Navbar
                    size={assetRoute}
                    visible={visible}
                    totalItems={cartArray.length > 0 ? cartArray.reduce((accumulator, item) => accumulator + item.quantity, 0) : 0}
                    handleDrawerToggle={handleDrawerToggle}/>
                <ThemeProvider theme={assetRoute === "desktop" ? theme : responsiveTheme}>
                    <Switch>
        
                            <Route exact path="/">
                                <Home size={assetRoute} />
                            </Route>
                            <Route path="/mentions-legales" exact>
                                <Mention size={assetRoute}/>
                            </Route>
                            <Route path="/conditions-generales-de-vente" exact>
                                <Policy size={assetRoute}/>
                            </Route>
                            <Route exact path="/oeuvres">
                                <Products loading={loadingStates} cart={cartArray} size={assetRoute} products={products} onAddToCart={handleAddToCart} handleUpdateCartQty />
                                <Snackbar
                                  open={openSnackbar}
                                  TransitionComponent={Slide}
                                  message={snackbarMessage}
                                  key={'top' + 'right'}
                                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                />
    
                            </Route>
                            <Route exact path="/panier">
                                <Cart
                                    loading={loadingStates}
                                    size={assetRoute}
                                    cart={cartArray}
                                    subTotal={subtotal}
                                    onUpdateCartQty={handleUpdateCartQty}
                                    onRemoveFromCart={handleRemoveFromCart}
                                    onEmptyCart={handleEmptyCart} />
                            </Route>
                            <Route path="/paiement" exact>
                                <Checkout
                                    cart={cart}
                                    order={order}
                                    onCaptureCheckout={handleCaptureCheckout}
                                    error={errorMessage}
                                    onEmptyCart={handleEmptyCart}
                                />
                            </Route>
                    </Switch>
                </ThemeProvider>
            </div>
        </Router >
    );
};

export default App;