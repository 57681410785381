import React from 'react';
import { AppBar, Toolbar, IconButton, Badge, Typography } from '@material-ui/core';
import { ShoppingCart } from '@material-ui/icons'
import { Link } from 'react-router-dom'
import clsx from 'clsx';

import useStyles from './styles';

const Navbar = ({ totalItems, visible, size }) => {
    const classes = useStyles();


    return (
            <AppBar style={{ background: 'white' }} position="fixed" className={clsx(classes.appBar, { [classes.appBarHidden]: !visible })} color="inherit">
                <Toolbar>
                    <IconButton component={Link} className={classes.cartButton} to="/" color="inherit">
                        <Typography variant="h6" className={classes.title2} color="inherit">
                           Page d'accueil
                        </Typography>
                    </IconButton>
                    <IconButton component={Link} className={classes.cartButton} to="/oeuvres"  color="inherit">
                        <Typography variant="h6" className={classes.title2} color="inherit">
                            Les œuvres
                        </Typography>
                        </IconButton>
                    {(
                        <div className={classes.button}>
                            <IconButton component={Link} className={classes.cartButton} to="/panier" arial-label="Show cart items" color="inherit">
                                <Typography variant="h6" className={classes.title2} color="inherit">
                                    {size === 'desktop' ? "Panier " : ""}
                                </Typography>
                                <Badge overlap="rectangular"  badgeContent={totalItems} color="secondary">
                                    <ShoppingCart />
                                </Badge> 
                            </IconButton>
                        </div>)}
                </Toolbar>
            </AppBar>
    )
};

export default Navbar;
