import React from 'react';
import { Grid } from '@material-ui/core';
import Select from 'react-select';

import Product from './Product/Product';
import Footer from '../Gallery/Footer';

import useStyles from './styles';

const options = [
    { slug: 'huile-sur-toile', label: 'Huile sur toile' },
    { slug: 'aquarelle', label: 'Aquarelle' },
    { slug: 'all', label: 'Tous' },
];

const Products = ({ loading, cart, size, products, onAddToCart }) => {
    const classes = useStyles();
    const [selectedCategory, setSelectedCategory] = React.useState(null);

    const handleCategoryChange = (selectedOption) => {
        setSelectedCategory(selectedOption);
    };

    const closeMenuOnOutsideClick = (e) => {
        if (!e.target.closest('.react-select')) {
        }
    };

    if (!products.length) return <p>Loading...</p>;

    // const debugFilterProducts = () => {
    //     const filteredProducts = products.filter((product) => {
    //         if (selectedCategory.slug === 'all') {
    //             return products; // Include all products if no category is selected
    //         }
    //         return product.categories.some(
    //             (category) => category.slug === selectedCategory.slug
    //         );
    //     });
    // };
    return (
        <div>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <div style={{marginBottom: '1%'}} onClick={closeMenuOnOutsideClick}>
                    <Select
                        slug={selectedCategory}
                        onChange={handleCategoryChange}
                        options={options}
                    />
                </div>
                <Grid container justifyContent="center" spacing={4}>
                    {products
                        .filter((product) => {
                            if (!selectedCategory || selectedCategory.slug === 'all') {
                                return true; // Include all products if no category is selected
                            }
                            return product.categories.some(
                                (category) => category.slug === selectedCategory.slug
                                );
                            })
                            .map((product) => (
                                <Grid key={product.id} item xs={12} sm={6} md={4} lg={3}>
                                <Product loading={loading} cart={cart} size={size} product={product} onAddToCart={onAddToCart} />
                            </Grid>
                        ))}
                </Grid>
            </main>
            <Footer
                role="contentinfo"
                bkgColor={"#151515"}
                color={"white"}
                hoverColor={"#7DF9FF"}
                size={size}
                />
        </div>
    );
};

export default Products;