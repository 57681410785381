import Hero from "../Gallery/Hero";
import Gallery from "../Gallery/Gallery";
import Footer from "../Gallery/Footer";

const Home = ({ size}) => {
  return (
    <>
      <Hero role="banner" size={size} />
      <Gallery role="main" size={size} />
      <Footer
        role="contentinfo"
        bkgColor={"#151515"}
        color={"white"}
        hoverColor={"#7DF9FF"}
        size={size}
      />
    </>
  );
};

export default Home;