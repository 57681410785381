import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: '#808080',
    padding: theme.spacing(3),
  },
  root: {
    flexGrow: 1,
  },
  select: {
    backgroundColor: 'red',
    '&:focus': {
      backgroundColor: 'red', // Change this to the desired color
      outline: 'none',
      boxShadow: 'none',
    },
  },
}));