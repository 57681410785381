import { makeStyles, alpha } from '@material-ui/core/styles';

const drawerWidth = 1;

export default makeStyles((theme) => ({
    appBar: {
        boxShadow: 'none',
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
        top: '0',
        transition: 'top 0.6s',
    },
    title: {
        backgroundColor: 'red',
        marginRight: '20px',
        alignItems: 'center',
        display: 'flex',
        textDecoration: 'none',
    },
    title2: {
        alignItems: 'center',
        display: 'flex',
        textDecoration: 'none',
    },
    image: {
        marginRight: '15px',
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    button: {
        marginLeft: 'auto',
        marginRight: '10px',
        [theme.breakpoints.down('xs')]: {
            fontSize: '0.7rem',
        },
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: '#323232',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
    appBarHidden: {
        top: '-68px',
        transition: 'top 0.6s',
    },
    onglet: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        width: '10%',
        height: '68px',
        transition: 'background-color 0.3s ease',
        '&:hover': {
            backgroundColor: '#F9F1F0', // color on hover
        },
    },
    cartButton: {
        height: '68px',
        justifyContent: 'flex-end',
        '&:hover': {
            backgroundColor: '#F9F1F0', // color on hover
        },
        borderRadius: '0px',
    }
}));