import React from 'react'

import useStyles from './styles'

import Footer from '../Gallery/Footer';

const Mention = ({size}) => {
    const classes = useStyles();

    return (
        <div>
        <div className={classes.container}>
          <h1 className={classes.title}>Mentions Légales</h1>
          <div className={classes.section}>
            <h2 className={classes.sectionTitle}>Informations légales</h2>
            <p>Raison sociale : Tristan Hosotte</p>
            <p>Adresse : 21 rue Saint Guilhem, 34000 Montpellier</p>
            <p>Téléphone fix: +33 4 67 67 17 34</p>
            <p>Téléphone: +33 6 83 27 68 83</p>
            <p>Courriel : tristanhosotte@yahoo.fr</p>
            <p>Forme juridique: EI (Entrepreneur Individuel)</p>
            <p>Numéro SIRET (siège) : 41881614600041</p>
            <p>Responsable de la publication : Andrea Hosotte</p>
          </div>
          <div className={classes.section}>
            <h2 className={classes.sectionTitle}>Hébergement</h2>
            <p>Nom : Hostinger</p>
            <p>Adresse : 61 Lordou Vironos Street, 6023 Larnaca, Chypre</p>
            <p>Contact : https://www.hostinger.fr/contact</p>
          </div>
          <div className={classes.section}>
           <h2 className={classes.sectionTitle}>Propriété intellectuelle</h2>
           <p>
                Toute reproduction, qu'elle soit partielle ou totale, de ce contenu 
                est formellement interdite sans une autorisation préalable de Tristan Hosotte, 
                conformément à la loi en vigueur. Les logos, visuels et marques affichés sur ce site 
                demeurent la propriété exclusive de leurs détenteurs respectifs, et sont protégés par 
                les dispositions de l'Article L 3335-2 et des suivants du code de la propriété intellectuelle.
            </p>
            <p>
                Pour toute information supplémentaire, veuillez nous contacter par e-mail à l'adresse suivante : tristanhosotte@yahoo.fr.
            </p>
         </div>
         <div className={classes.section}>
           <h2 className={classes.sectionTitle}>Utilisation et Utilisateur du site</h2>
           <p>
                L'accès au site engage toute personne, indépendamment de sa localisation, 
                des modalités de connexion, et de l'objectif de sa consultation, en tant qu'utilisateur. 
                Il est impératif que chaque utilisateur prenne conscience des risques liés à l'utilisation d'Internet. 
                En aucun cas, l'éditeur ne peut être tenu responsable de ces risques et de leurs éventuelles 
                conséquences préjudiciables pour l'utilisateur. La responsabilité de protéger ses propres données 
                incombe à chaque utilisateur, qui doit prendre les mesures nécessaires à cet effet.
            </p>
            <p>
                Les informations publiées sur le site sont destinées à la consultation exclusive. 
                Il est possible que le site soit lié à d'autres sites via des liens hypertextes, 
                bien que cela puisse se faire à l'insu de Visicod Communication. 
                L'éditeur décline toute responsabilité quant aux informations présentées sur ces sites externes. 
                La création de liens pointant uniquement vers la page d'accueil du site est autorisée, 
                à condition qu'ils s'ouvrent dans une nouvelle fenêtre du navigateur. Cependant, 
                l'éditeur se réserve le droit de mettre fin à cette tolérance à tout moment s'il estime 
                que le lien établi est préjudiciable à l'intérêt du site. Toute autre utilisation est strictement interdite.
            </p>
            <p>
                Tout utilisateur agissant en violation des présentes Conditions Générales d'utilisation s'expose à des poursuites civiles et pénales. 
                L'éditeur se réserve le droit d'engager des procédures pénales contre l'utilisateur en cas de fraude avérée.
            </p>
         </div>
         <div className={classes.section}>
           <h2 className={classes.sectionTitle}>Données personnelles</h2>
           <h4>Collecte de données personnelles:</h4>
            <p>
                En utilisant notre plateforme de commerce électronique, vous consentez à la collecte de 
                certaines données personnelles nécessaires au traitement de vos commandes et à 
                l'amélioration de votre expérience d'achat. Les informations recueillies incluent, 
                sans s'y limiter, votre nom, votre adresse, votre adresse e-mail, et des détails de paiement.
            </p>
           <h4>Utilisation des données :</h4>
                <p>
                    Les données personnelles collectées sont utilisées exclusivement aux fins de traitement des commandes, 
                    de communication avec vous concernant vos achats, et d'amélioration continue de nos services. 
                    Nous ne partageons pas vos informations avec des tiers autres que ceux nécessaires au bon déroulement 
                    de votre transaction, tels que Stripe pour le traitement des paiements 
                    et Commerce.js pour la gestion de la plateforme.
                </p>
           <h4>Sécurité des données :</h4>
                <p>
                    Nous prenons des mesures de sécurité appropriées pour protéger vos données personnelles 
                    contre tout accès non autorisé, divulgation, altération ou destruction. 
                    Les transactions financières sont sécurisées via Stripe, 
                    une plateforme de paiement réputée, assurant un niveau élevé de sécurité.
                </p>
           <h4>Consentement :</h4>
                <p>
                En utilisant notre site, vous consentez à la collecte et à l'utilisation de vos 
                données personnelles conformément à la présente politique de confidentialité. 
                Vous pouvez à tout moment demander l'accès, la rectification ou la suppression 
                de vos données personnelles en nous contactant à l'adresse électronique suivante : tristanhosotte@yahoo.fr.
                </p>
         </div>
         <div className={classes.section}>
           <h2 className={classes.sectionTitle}>Modifications de la Politique de Confidentialité</h2>
            <p>
                Nous nous réservons le droit de modifier notre politique de confidentialité à tout moment. 
                Les modifications seront publiées sur cette page, et nous vous encourageons à la consulter régulièrement.
                Pour toute question ou préoccupation concernant la confidentialité, veuillez nous contacter sur tristanhosotte@yahoo.fr.
            </p>
        </div>
    </div>
        <Footer
            role="contentinfo"
            bkgColor={"#151515"}
            color={"white"}
            hoverColor={"#7DF9FF"}
            size={size}
        />
    </div>
    );
}

export default Mention