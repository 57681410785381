import { Container, Typography, Grid } from "@mui/material";

import { Link } from 'react-router-dom'

import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";


const Footer = ({ bkgColor, color, hoverColor, size }) => {
  const margins =
    size === "desktop" ? "80%" : size === "tablet" ? "95%" : "95%";

  return (
    <Container
      maxWidth="100%"
      sx={{
        backgroundColor: `${bkgColor}`,
        // height: "244px",
        padding: "80px 0",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Grid
        container
        maxWidth={margins}
        spacing={4}
        justifyContent="space-between"
      >
        <Grid container item lg={10} sm={8}>
          <Grid item lg={4} sm={6} xs={12}>
            <Typography color={color} variant="h4">
              Tristan <br />
              Hosotte
            </Typography>
          </Grid>
          <Grid item lg={6} sm={6} xs={12}>
            <Link to="/mentions-legales" style={{color: 'white', fontSize: '16px', cursor: 'pointer' }}>© Mentions légales</Link>
            <br />
            <Link to="/conditions-generales-de-vente" style={{color: 'white', fontSize: '16px', cursor: 'pointer' }}>Conditions générales de vente</Link>
            <Typography color={color} variant="body1">
                21 rue Saint Guilhem, Montpellier 34000
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          color={color}
          justifyContent={size === "mobile" ? "left" : "right"}
          lg={2}
          sm={1}
          xs={12}
        >
          <a href="https://www.facebook.com/tristan.hosotte" target="_blank" rel="noopener noreferrer" aria-label="facebook">
            <FacebookIcon
              fontSize="large"
              sx={{ color: `${color}`, "&:hover": { color: `${hoverColor}` } }}
            />
          </a>
          <a href="https://www.instagram.com/tristanhosotte/" target="_blank" rel="noopener noreferrer" aria-label="instagram" sx={{ margin: "0 20px" }}>
            <InstagramIcon
              fontSize="large"
              sx={{ color: `${color}`, "&:hover": { color: `${hoverColor}` } }}
            />
          </a>
          <div style={{width: '100%'}}/>
        <Typography>
          +33 6 83 27 68 83
        </Typography>
          <div style={{width: '100%'}}/>
        <Typography >
          tristanhosotte@yahoo.fr
        </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Footer;