import React from 'react';

import { Typography, Card, CardActions, CardContent, CardMedia } from '@material-ui/core';
import { LoadingButton } from '@mui/lab'


import useStyles from './styles'

const CartItem = ({ loading, item, onUpdateCartQty, onRemoveFromCart }) => {
    const classes = useStyles();

    const handleRemoveFromCart = (lineItemId) => onRemoveFromCart(lineItemId);


    return (
        <Card className={classes.root}>
            <div style={{position: 'relative'}}>
                <CardMedia image={item.image.url} alt={item.name} className={classes.media} />
            </div>
            <CardContent >
                <div className={classes.cardContent}>
                    <Typography variant="h4" style={{fontFamily: "Big Shoulders Display"}}>{item.name}</Typography>
                </div>
                <div className={classes.cardContent}>
                <Typography variant="h5" style={{fontFamily: "Big Shoulders Display"}}>{item.line_total.raw}€</Typography>
                </div>
            </CardContent>
            <CardActions className={classes.cardActions}>
                <LoadingButton
                        variant="contained" 
                        style={{backgroundColor: '#D6D6DC'}} 
                        loading={loading[item.id]} 
                        label="Ajouter au panier" 
                        className={classes.priceButtonMod} 
                        onClick={() => handleRemoveFromCart(item.id)}
                        sx={{color: 'black', fontSize: '17px'}}
                        >
                        Supprimer
                    </LoadingButton>
                
            </CardActions>
        </Card>
    );
};

export default CartItem;
