import React, { useState, useEffect } from 'react';
import { InputLabel, Select, MenuItem, Button, Grid, Typography, FormControlLabel, TextField } from '@material-ui/core';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';
import Checkbox from '@material-ui/core/Checkbox';
import { Link } from 'react-router-dom';

import { commerce } from '../../lib/commerce';

const AddressForm = ({ checkoutToken, next }) => {
    const [shippingCountries, setShippingCountries] = useState([]);
    const [shippingCountry, setShippingCountry] = useState('');
    const [shippingSubdivisions, setShippingSubdivisions] = useState([]);
    const [shippingSubdivision, setShippingSubdivision] = useState('');
    const [shippingOptions, setShippingOptions] = useState([]);
    const [shippingOption, setShippingOption] = useState('');
    const [shippingPrice, setShippingPrice] = useState([]);
    const [termsChecked, setTermsChecked] = useState(false);
    const methods = useForm();

    if (!checkoutToken && !shippingOptions) {
        console.log("loading...");
    }

    const fetchShippingCountries = async (checkoutTokenId) => {
        const { countries } = await commerce.services.localeListShippingCountries(checkoutTokenId);

        setShippingCountries(countries);
        setShippingCountry(Object.keys(countries)[0]);
    };

    const fetchSubdivisions = async (countryCode) => {
        const { subdivisions } = await commerce.services.localeListSubdivisions(countryCode);

        setShippingSubdivisions(subdivisions);
        setShippingSubdivision(Object.keys(subdivisions)[0]);
    }

    const fetchShippingOptions = async (checkoutToken, country, region = null) => {
        const options = await commerce.checkout.getShippingOptions(checkoutToken.id, { country, region });
        
        const shippingMethod = checkoutToken.shipping_methods.find(method => method.id === options[0].id);
        options[0].price = shippingMethod.price;

        setShippingOptions(options);
        setShippingOption(options[0].id);
    }

    useEffect(() => {
        fetchShippingCountries(checkoutToken.id);
    }, []);
    
    useEffect(() => {
        if (shippingCountry)
            fetchSubdivisions(shippingCountry);
    }, [shippingCountry]);
    
    useEffect(() => {
        if (shippingSubdivision) 
            fetchShippingOptions(checkoutToken, shippingCountry, shippingSubdivision);
    }, [shippingSubdivision]);

    const { handleSubmit, register } = methods;

    return (
        <>
          <Typography variant="h6" gutterBottom>Information et adresse de livraison</Typography>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit((data) => next({ ...data, shippingCountry, shippingSubdivision, shippingOption, shippingPrice }))}>
              <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField required {...register('firstname')} id="firstName" name="firstname" label="Nom" fullWidth autoComplete='given-name' variant='standard'/>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField required {...register('lastname')} id="lastName" name="lastname" label="Prénom" fullWidth autoComplete='family-name' variant='standard'/>
              </Grid>
              <Grid item xs={12}>
                <TextField required {...register('address')} id="address" name="address" label="Adresse" fullWidth autoComplete='address' variant='standard'/>
              </Grid>
              <Grid item xs={12}>
                <TextField required {...register('email')} id="email" name="email" label="E-mail" fullWidth autoComplete='mail' variant='standard'/>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField required {...register('city')} id="city" name="city" label="Ville" fullWidth autoComplete='city' variant='standard'/>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField required {...register('zip')} id="zip" name="zip" label="Code postal" fullWidth autoComplete='zip-code' variant='standard'/>
              </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel>Pays</InputLabel>
                  <Select value={shippingCountry} fullWidth onChange={(e) => setShippingCountry(e.target.value)}>
                    {Object.entries(shippingCountries).map(([code, name]) => ({ id: code, label: name })).map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel>Département/Région</InputLabel>
                  <Select value={shippingSubdivision} fullWidth onChange={(e) => setShippingSubdivision(e.target.value)}>
                    {Object.entries(shippingSubdivisions).map(([code, name]) => ({ id: code, label: name })).map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12} sm={6}>
                 <InputLabel>Mode de livraison</InputLabel>
                 <Select value={shippingOption} fullWidth onChange={(e) => {
                    setShippingOption(e.target.value)
                    const selectedOption = shippingOptions.find(option => option.id === e.target.value);
                    if (selectedOption) {
                      setShippingPrice(selectedOption.price)
                    }
                    }}>
                  {shippingOptions.map((sO) => ({ id: sO.id, label: `${sO.description} - (${sO.price.formatted_with_symbol})`, value: sO })).map((item) => (
                    <MenuItem key={item.id} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                 </Select>
                </Grid>

              </Grid>
              <FormControlLabel
                            control={
                                <Checkbox
                                    checked={termsChecked}
                                    onChange={(e) => setTermsChecked(e.target.checked)}
                                    color="primary"
                                />
                            }
                            label={
                                <span>
                                  J'ai lu et j'accepte les{" "}
                                  <Link to="/conditions-generales-de-vente" style={{cursor: 'pointer' }} color="primary">
                                     conditions générales de vente
                                  </Link>
                                </span>
                              }
                        />
              <br />
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
               <Button component={Link} variant="outlined" to="/cart">Retour</Button>
               <Button type="submit" variant="contained" color="primary" disabled={!termsChecked}>Suivant</Button>
              </div>
            </form>
          </FormProvider>
        </>
      );
};

export default AddressForm;