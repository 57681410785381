import React from 'react';
import { Typography, List, ListItem, ListItemText } from '@material-ui/core';

const Review = ({checkoutToken, shippingID}) => {


  return (
    <>
        <Typography variant="h6" gutterBottom>Résumé de la commande</Typography>
        <List disablePadding>
            {checkoutToken.line_items.map((product) => (
                <ListItem style={{padding: '10px 0'}} key={product.name}>
                    <ListItemText primary={product.name}/>
                    <Typography variant="body2">{product.line_total.raw}€</Typography>
                </ListItem>
            ))}
            <ListItem style={{padding: '10px 0'}} key={shippingID.description}>
                <ListItemText primary={"Livraison : " + shippingID.description}/>
                <Typography variant="body2">{shippingID.price.raw}€</Typography>
            </ListItem>
            <ListItem style={{padding: '10px 0'}}>
                <ListItemText primary="Total"/>
                <Typography variant="subtitle1" style={{ fontWeight: 700}}>
                    {checkoutToken.subtotal.raw + shippingID.price.raw}€
                </Typography>
            </ListItem>
        </List>
    </>
  )
}

export default Review;
